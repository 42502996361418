@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.WordLineList {

  .word-item {
    @include props-dpr(font-size height line-height, 40px 84px 84px);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    @include ellipsis;
    @include props-dpr(border-bottom, 1px solid rgba(255,255,255,0.15));
  }
}