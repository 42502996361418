/*
 * @Author: paco 
 * @Date: 2018-04-11 15:20:15 
 * @Last Modified by: paco
 * @Last Modified time: 2018-04-12 10:54:18
 */

// px单位转成vw单位
@function px2vw($size: 14px, $width: 4000px) {
  @if (type-of($size) == "number" and unit($size) == 'px') {
    @return $size * 100vw / $width
  }
  @else {
    @return $size
  }
}

//props-dpr
@mixin props-dpr($props, $values) {
  @if length($props)==1 {
    @if length($values)==1 {
      #{$props}: px2vw($values);
    } @else {
      $valueStr: ();
      @each $value in $values {
        $valueStr: append($valueStr, px2vw($value));
      }
      #{$props}: $valueStr;
    }
  } @else {
    @each $prop in $props {
      #{$prop}: px2vw(nth($values, index($props, $prop)));
    }
  }
}

// translate center
@mixin center-translate($direction: both) {
  position: absolute;
  @if $direction==both {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  } @else if $direction==x {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  } @else if $direction==y {
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }
}

%center-translate {
  @include center-translate;
}

// ellipsis
@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

%ellipsis {
  @include ellipsis;
}

// ellipsis multi
@mixin ellipsis-lines($lines: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  word-break: break-all;
}

// clearfix
@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%clearfix {
  @include clearfix;
}

// triangle 三角箭头
%triangle-basic {
  content: "";
  height: 0;
  width: 0;
  overflow: hidden;
}

@mixin triangle($direction: bottom, $borderWidth: 12px, $borderColor: $gray6) {
  @extend %triangle-basic;
  @if $direction==top {
    border-bottom: $borderWidth solid $borderColor;
    border-left: $borderWidth dashed transparent;
    border-right: $borderWidth dashed transparent;
    border-bottom-width: $borderWidth;
    border-left-width: $borderWidth;
    border-right-width: $borderWidth;
  } @else if $direction==right {
    border-left: $borderWidth solid $borderColor;
    border-top: $borderWidth dashed transparent;
    border-bottom: $borderWidth dashed transparent;
    border-bottom-width: $borderWidth;
    border-left-width: $borderWidth;
    border-top-width: $borderWidth;
  } @else if $direction==bottom {
    border-top: $borderWidth solid $borderColor;
    border-left: $borderWidth dashed transparent;
    border-right: $borderWidth dashed transparent;
    border-top-width: $borderWidth;
    border-left-width: $borderWidth;
    border-right-width: $borderWidth;
  } @else if $direction==left {
    border-right: $borderWidth solid $borderColor;
    border-top: $borderWidth dashed transparent;
    border-bottom: $borderWidth dashed transparent;
    border-bottom-width: $borderWidth;
    border-top-width: $borderWidth;
    border-right-width: $borderWidth;
  }
}