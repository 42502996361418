@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.big-image-wrap_tkyEkcEE {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  z-index: 20;
  cursor: pointer;
  .item-img {
    width: 80%;
    height: 80%;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    @include center-translate;
  }
}