@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";
#Product {
  position: relative;
  overflow-y: hidden;
  /*标题部分*/
  .header-part {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #20212b;
    @include props-dpr(width, 2100px);
    @include props-dpr(height, 264px);
    overflow: hidden;
    z-index: 9999;
  }

  #yinYong {
    float: right;
  }

  #shangPin {
    float: right;
  }

  .down {
    background-color: #75aff8;
    @include props-dpr(height, 20px);
    position: absolute;
    @include props-dpr(bottom, -20px);
    width: 100%;
    left: 0%;
  }

  .button {
    position: relative;
    background-color: #2f3037;
    cursor: pointer;
    color: #75aff8;
    @include props-dpr(padding, 110px 60px);
    @include props-dpr(font-size, 63px);
    @include props-dpr(line-height, 25px);
    @include props-dpr(letter-spacing, 1px);
  }

  .button-click,
  .down-color {
    background-color: #20212b;
    color: #4a5665;
  }

  /*内容部分*/

  /*背景图*/
  #content {
    margin: 0;
    @include props-dpr(width height, 3559px 1722px);
    background-size: 100% 100%;
    @include props-dpr(padding, 158px 0 0 0);
  }

  /*背景图*/
#association-book {
  position: relative;
  margin: 0;
  img {
    @include props-dpr(width height, 100% 100%);
    z-index: 9;
  }
  .leftCode,.rightCode{
    position: absolute;
    width: 9%;
    height: 19%;
    z-index: 9;
  }
  .leftCode{
    left: 12%;
    top: 25%;
  }
  .rightCode{
    left: 54.2%;
    top: 25%;
  }
}

  /*文字区*/
  .content-word-top {
    @include props-dpr(padding, 0 0 0 328px);
  }

  .title-picture {
    @include props-dpr(width, 1390px);
    @include props-dpr(margin-left, 360px);
  }

  /*二维码部分*/
  .content-picture-top {
    @include props-dpr(margin, 240px 700px 0 246px);
    @include props-dpr(width, 80%);
    float: left;
  }

  .image-content {
    //@include props-dpr(height, 105px);
    @include props-dpr(font-size, 42px);
    @include props-dpr(letter-spacing, 1px);
    color: #75aff8;
    text-align: center;
    @include props-dpr(margin-top, 15px);
  }

  .image-name {
    @include props-dpr(font-size, 63px);
    letter-spacing: 1px;
    color: #eef9ff;
    text-align: center;
    @include props-dpr(margin-top, 42px);
    @include props-dpr(margin-bottom, 30px);
  }

  .images {
    display: block;
    margin: auto;
    @include props-dpr(width height, 260px 260px);
  }

  .image-frame {
    @include props-dpr(margin-right, 142px);
  }

  .QR-div {
    @include props-dpr(margin-bottom, 112px);
    float: left;
    width: 20%;
  }

  /*手机图片*/
  .phone {
    //overflow: hidden;
    position: absolute;
    @include props-dpr(left, 3087px);
    @include props-dpr(top, 800px);
  }

  .phone-border {
    position: relative;
    z-index: 2;
    @include props-dpr(width, 556px);
    @include props-dpr(height, 1113px);
  }

  .phone-image {
    @include props-dpr(width, 483px);
    @include props-dpr(height, 1046px);
    position: absolute;
    display: inline-block;
    animation-name: fadeInOut;
    animation-timing-function: ease-in-out;
    animation-duration: 8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }
  .noAnimation{
    animation-name: none;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0.1;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
  .phone-border {
    position: relative;
    z-index: 2;
    @include props-dpr(width, 556px);
    @include props-dpr(height, 1113px);
  }
  .left-phone{
    position: absolute;
    z-index: 3;
    @include props-dpr(width, 556px);
    @include props-dpr(height, 1113px);
    @include props-dpr(left, 1020px);
    @include props-dpr(top, 450px);
  }
  .right-phone{
    position: absolute;
    z-index: 3;
    @include props-dpr(width, 556px);
    @include props-dpr(height, 1113px);
    @include props-dpr(right, 730px);
    @include props-dpr(top, 450px);
  }
  .image-box{
    @include props-dpr(width, 483px);
    @include props-dpr(height, 1046px);
    position: absolute;
    top: 3%;
    left: 7%;
    z-index: 1;
    img{
      z-index: 2;
      border-radius: 15px;
    }
  }
  .phone-title {
    position: absolute;
    @include props-dpr(left, 104px);
    top: -58px;
    @include props-dpr(width, 348px);
    @include props-dpr(height, 58px);
  }
  .image-back {
    @include props-dpr(width, 483px);
    @include props-dpr(height, 1046px);
    background-color: #fff;
    border-radius: 15px;
    position: absolute;
    top: 3%;
    left: 7%;
  }
  .change-screen {
    z-index: 9999;
    position: fixed;
    @include props-dpr(right bottom, 100px 280px);
  }
}

