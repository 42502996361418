@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.newHome {
    * {
        box-sizing: border-box;
    }

    .weather {
        position: fixed;
        z-index: 10;
        bottom: unset;
        right: unset;
        @include props-dpr(top left, 0px 55px);
        @include props-dpr(height, 80px);
        @include props-dpr(font-size, 32px);
        color: #FFFFFF;
        display: flex;
        align-items: flex-end;
        .time-content {
            @include props-dpr(font-size, 55px);
            line-height: 1.1;
        }
        .divider {
            opacity: 0;
            @include props-dpr(margin, 0 15px);
        }
        .btn {
            position: fixed;
            @include props-dpr(top right, 10px 55px);
            @include props-dpr(width height line-height font-size, 160px 80px 80px 55px);
            font-weight: normal;
        }
    }
    .head {
        @include props-dpr(margin-top, 70px);
        width: 100vw;
        @include props-dpr(height, 200px);
        background: url(https://oss.5rs.me/oss/uploadfe/png/20e63a950a5b8d9897646e8c50516321.png);
        @include props-dpr(background-position, center 40px);
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;
        position: relative;

        p {
            color: #fff;
            font-weight: bold;
            text-shadow: 0px 3px 3px rgba(0, 25, 110, 0.75);
            background: linear-gradient(0deg, #0A93F3 0%, #99F6F9 100%);
            background-clip: text;
            text-fill-color: transparent;
            @include props-dpr(font-size line-height, 72px 115px);

        }

    }

    .body {
        @include props-dpr(padding-top padding-left padding-right, 10px 110px 200px);
        display: flex;
        justify-content: space-around;

        .middle {
            @include props-dpr(margin-left margin-right, 40px 40px)
        }

        .cumulative-readers {
            position: relative;
            @include props-dpr(height padding-left, 460px 30px);

            .cumulative {
                position: absolute;
                @include props-dpr(top, 40px);
                width: 100%;

                .posab {
                    display: flex;
                    justify-content: center;

                    .num-content {
                        background: url(../../assets/images/numBg.png);
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        @include props-dpr(width height line-height font-size, 166px 238px 238px 190px);
                        text-align: center;
                        .num-wrapper{
                            margin: px2vw(15px) 0 ;
                            @include props-dpr(width height line-height font-size, 166px 208px 200px 190px);
                            overflow: hidden;
                        }
                        .num {
                            @include props-dpr(width height line-height font-size, 166px 208px 200px 190px);
                            font-weight: bold;
                            color: #00DEFF;
                        }

                        .num_1 {
                            animation: num_1 2s forwards;
                        }

                        .num_2 {
                            animation: num_2 2s forwards;
                        }

                        .num_3 {
                            animation: num_3 2s forwards;
                        }

                        .num_4 {
                            animation: num_4 2s forwards;
                        }

                        .num_5 {
                            animation: num_5 2s forwards;
                        }

                        .num_6 {
                            animation: num_6 2s forwards;
                        }

                        .num_7 {
                            animation: num_7 2s forwards;
                        }

                        .num_8 {
                            animation: num_8 2s forwards;
                        }

                        /*省略num_2——num_8*/

                        .num_9 {
                            animation: num_9 2s forwards;
                        }

                        @keyframes num_1
                            {
                            0% {
                                transform: translate3d(0, 0, 0);
                            }
                            100% {
                                transform: translate3d(0, px2vw(-208px), 0);
                            }
                        }

                        @keyframes num_2
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-416px), 0);
                            }
                        }

                        @keyframes num_3
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-624px), 0);
                            }
                        }

                        @keyframes num_4
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-832px), 0);
                            }
                        }

                        @keyframes num_5
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-1040px), 0);
                            }
                        }

                        @keyframes num_6
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-1248px), 0);
                            }
                        }

                        @keyframes num_7
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-1456px), 0);
                            }
                        }

                        @keyframes num_8
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-1664px), 0);
                            }
                        }

                        @keyframes num_9
                            {
                            0% {
                                transform: translate3d(0, 0px, 0);
                            }

                            100% {
                                transform: translate3d(0, px2vw(-1872px), 0);
                            }
                        }
                    }
                }
            }

            .readers {
                background: url(../../assets/images/cumulative.png) no-repeat center/ 100% 100%;
                @include props-dpr(width height, 1296px 196px);
                margin: 0 auto;
                position: absolute;
                bottom: 0;

                p {
                    @include props-dpr(font-size line-height, 42px 110px);
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    color: #60CFF7;
                    text-shadow: 0px 2px 2px rgba(0, 25, 110, 0.75);
                    background: linear-gradient(0deg, #0A93F3 0%, #99F6F9 100%);
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }
        }

        .distribution {
            margin: 0 auto;
            @include props-dpr(width margin-top margin-bottom, 1296px 100px 100px);
            display: flex;
            justify-content: space-around;

            .distribution_card {
                @include props-dpr(width height, 370px 220px);
                @include props-dpr(border, 6px solid #184BDC);
                @include props-dpr(border-radius, 16px);
                @include props-dpr(font-size, 50px);
                background-color: rgba(#184BDC, 0.1);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #00DEFF;
            }
        }

        .statistical {
            @include props-dpr(width height, 1361px 975px);
            background: url(../../assets/images/distribution.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            .ECharts {
                @include props-dpr(padding-right, 40px);
                @include props-dpr(margin-left, -40px);
            }
            p {
                @include props-dpr(height font-size, 55px 38px);
                text-align: center;
                font-weight: bold;
                color: #60CFF7;
                text-shadow: 0px 5px 5px rgba(0, 25, 110, 0.75);
                background: linear-gradient(0deg, #0A93F3 0%, #99F6F9 100%);
                background-clip: text;
                text-fill-color: transparent;
            }
        }
    }

    .leftTopContent {
        .aggregateData {
            @include props-dpr(height, 272px);
            width: 100%;
            display: flex;
            align-items: center;

            img {
                @include props-dpr(width height margin-left, 24px 24px 10px);
            }

            .L,
            .R {
                @include props-dpr(width, 511px);
                text-align: center;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                b {
                    @include props-dpr(font-size, 72px);
                    color: #00DEFF;
                }

                span {
                    @include props-dpr(font-size, 28px);
                    font-weight: 500;
                    color: #FFFFFF;
                }
            }

            .C {
                @include props-dpr(width height, 4px 82px);
                background: rgba(0, 222, 255, 0.2);
            }

            .R {
                @include props-dpr(width, 530px);

                h1 {
                    margin: 0;
                    @include props-dpr(font-size, 72px);
                    font-weight: 500;
                    color: #00DEFF;

                    span {
                        @include props-dpr(font-size, 28px);
                        font-weight: 500;
                        color: #00DEFF;
                    }
                }
            }
        }
    }

    .leftBContent {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 97%;
    }

    .echart {
        @include props-dpr(height, 744px);
        width: 100%;
    }

    .change-screen {
        z-index: 9999;
        position: fixed;
        @include props-dpr(right bottom, 100px 280px);
    }
}
