@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.SampleCaseList {
  width: 100%;
  height: 100%;
  // border: 1px solid red;
  // @include props-dpr(padding, 180px 30px 60px 50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .list-item-wrap {
    &.item_0,
    &.item_2 {
      @include props-dpr(width height, 540px 540px);
      @include props-dpr(border-radius, 10px);
      overflow: hidden;
      transform: scale(0.8);
      opacity: 0.4;
    }
    &.item_1 {
      .list-item {
        position: absolute;
        @include center-translate;
        z-index: 2;
        @include props-dpr(width height, 540px 540px);
        overflow: hidden;
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          @include props-dpr(top left bottom right, -10px -10px -10px -10px);
          background-image: url(https://oss.5rs.me/oss/uploadfe/png/089006f32d13db9547f4cce7548f8bf9.png);
          // @include props-dpr(background-size, 578px 526px);
          background-size: 100% 100%;
          background-position: center center;
          background-repeat: no-repeat;
          z-index: 1;
        }
      }
    }
    .list-item {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-size: 100% 100%;
      transition: background-image 0.3s ease;
    }
  }
}