@charset "UTF-8";
@import "../../../assets/sass/_mixin.scss";
@import "../../../assets/sass/_variables.scss";

.Tooltip {
  display: inline-block;
  vertical-align: middle;
}

.ToolTipContainer {
  position: fixed;
  left: 0;
  top: -11px;
  width: 100%;
  z-index: 200;
  color: $gray3;
  .tool-tip-inner {
    position: absolute;
    padding: 12px 15px 12px 18px;
    font-size: 14px;
    border-radius: 3px;
    box-shadow: 1px 1px 6px 0 rgba(102, 102, 102, 0.4);
    background-color: $white;
  }

  &.HIDDEN {
    overflow: hidden;
  }

  &:not(.HIDDEN) {
    .tool-tip-inner {
      transition: transform 0.3s;
    }
  }
}
