@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.BorrowList_zqTzZz00 {
  position: relative;
  @include props-dpr(padding-top, 50px);
  .unit-text {
    @include props-dpr(font-size, 28px);
    color: #fff;
    position: absolute;
    top: 0;
    @include props-dpr(right, 90px);
  }
  .list-wrap {
    display: flex;
    justify-content: space-between;
    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-title {
        @include props-dpr(padding, 0 10px);
      }
      .inner-list {
        @include props-dpr(width height, 385px 571px);
        @include props-dpr(padding, 50px 25px 50px 50px);
        @include props-dpr(margin-top, 30px);
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/20900812ad191912152fcbab68def742.png);
        .user-item {
          @include props-dpr(height line-height font-size, 36px 36px 34px);
          @include props-dpr(margin-bottom, 30px);
          font-family: Source Han Sans CN;
          font-weight: 400;
          display: flex;
          justify-content: space-between;
        }
        .user-name {
          color: #54C4F7;
          // @include props-dpr(width, 220px);
          @include ellipsis-lines(1);
        }
        .book-count {
          // @include props-dpr(width, 80px);
          color: #FF8602;
          text-align: center;
        }
      }
    }
  }
}
