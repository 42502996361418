@charset "UTF-8";
@import "../../assets/sass/_mixin.scss";

.data {
  width: 100%;
  position: relative;
  .data-content {
    @include props-dpr(padding-top padding-left padding-right font-size, 115px 179px 165px 62px);
    .header {
      // background: url('https://oss.5rs.me/oss/uploadfe/png/a36078bc3a303a5f83fc481d12facfca.png') no-repeat;
      // background-size: cover;
      display: flex;
      justify-content: space-between;
      @include props-dpr(margin-bottom, 160px);
      .header-item {
        @include props-dpr(width height, 966px 596px);
        background: url(../../assets/images/tongjiBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        text-align: center;
        p {
          @include props-dpr(font-size, 160px);
          font-weight: bold;
          color: #FFFFFF;
          line-height: 180px
        }
        span {
          @include props-dpr(font-size , 60px);
          font-weight: 500;
          color: #5AA1F2;
        }
      }
      // .header-left {
      //   box-sizing: border-box;
      //   @include props-dpr(
      //       padding-right height width font-size line-height,
      //       50px 210px 1490px 62px 240px
      //   );
      //   text-align: right;
      //   color: #78b3ff;
      // }
    }

    //.Number{
    //  @include props-dpr(margin-top margin-bottom ,  150px 130px );
    //}

    .total-money {
      position: relative;
      @include props-dpr(margin-top margin-bottom height, 150px 160px 346px);
      text-align: center;
      color: #eef9ff;
      //border: 1px solid red;
      .money {
        position: absolute;
        @include props-dpr(top font-size, 200px 430px);
        left: 50%;
        letter-spacing: 20px;
        width: fit-content;
        transform: translate(-41.5%, -50%);
        &:before{
          position: absolute;
          display: inline-block;
          content: "";
          @include props-dpr(top left width height, 30px -400px 245px 295px);
          background: url(https://file.5rs.me/oss/uploadfe/png/ceccafc2660d96e8c45720fe9dd09dc5.png);
          background-size: cover;
        }
      }
    }

    .footer {
      @include props-dpr(padding-left padding-right padding-bottom, 43px 68px 73px);
      .footer-left {
        position: relative;
        @include props-dpr(width height, 2200px 832px);
        //overflow: hidden;
        .echart{
          @include props-dpr(height width, 832px  2400px);
        }
        .tooltips{
          position: absolute;
          @include props-dpr(top right height width, 10px 110px 250px 525px);
          background: url(https://file.5rs.me/oss/uploadfe/png/af5c49dcd9159989fcb2f517ed6bf07d.png) no-repeat bottom;
          background-size: contain;
          .sale-date, .sale-money{
            color: #eef9ff;
            @include props-dpr(font-size letter-spacing, 56px 6px);
          }
          .sale-date{
            @include props-dpr(margin-top, 20px);
          }
        }
      }
      .footer-right {
        @include props-dpr(width, 1098px);
        .box-one, .box-two {
          @include props-dpr(width height, 1098px 390px);
          overflow: hidden;
          background: url(https://file.5rs.me/oss/uploadfe/png/bed221d4ab3b8158180aaa416af221d3.png) no-repeat left,
          url(https://file.5rs.me/oss/uploadfe/png/f9a4803038fe5d6086171ed9e2f58b11.png) no-repeat right;
          background-size: contain;
          text-align: center;
          .box {
            display: inline-block;
            width: fit-content;
            @include props-dpr(margin-top margin-bottom padding-left padding-right padding-top padding-bottom, 20px 30px 70px 70px 20px 20px);
            background: url(https://file.5rs.me/oss/uploadfe/png/4146e7bec95617968d812e3ffe957547.png) no-repeat center;
            background-size: cover;
            .data-name {
              color: #78b3ff;
            }
            .num {
              display: inline-block;
              @include props-dpr(margin-top font-size letter-spacing, 40px 184px 6px);
              color: #fff;
            }
          }
        }
        .box-one {
          @include props-dpr(margin-bottom, 52px);
        }
        .box-two {
          @include props-dpr(margin-top, 200px);
        }
      }
    }
  }
  .change-screen {
    z-index: 9999;
    position: fixed;
    @include props-dpr(right bottom, 100px 280px);
  }
}
