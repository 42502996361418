@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.BigClassroom_tkyEkcEE {
    * {
        box-sizing: border-box;
    }

    .weather {
        position: fixed;
        z-index: 10;
        bottom: unset;
        right: unset;
        @include props-dpr(top left, 20px 55px);
        @include props-dpr(height, 80px);
        @include props-dpr(font-size, 32px);
        color: #FFFFFF;
        display: flex;
        align-items: flex-end;
        .time-content {
            @include props-dpr(font-size, 55px);
            line-height: 1.1;
        }
        .divider {
            opacity: 0;
            @include props-dpr(margin, 0 15px);
        }
        .btn {
            display: none;
            // position: fixed;
            // @include props-dpr(top right, 2px 55px);
            // @include props-dpr(width height line-height font-size, 160px 80px 80px 55px);
            // font-weight: normal;
        }
    }
    .head {
        width: 100vw;
        @include props-dpr(height, 200px);
        @include props-dpr(margin-top, 40px);
        background: url(https://oss.5rs.me/oss/uploadfe/png/7dd137c71c9c664812f8ae8dba71f033.png);
        @include props-dpr(background-position, center 40px);
        background-size: 100%;
        background-repeat: no-repeat;
        text-align: center;
        position: relative;

    }

    .body {
        @include props-dpr(padding-top padding-left padding-right, 40px 110px 200px);
        display: flex;
        .left-container {
            .resource-chart-wrap {
                @include props-dpr(width height, 1264px 842px);
                @include props-dpr(padding, 140px 40px 0 90px);
                background-size: 100% 100%;
                background-image: url(https://oss.5rs.me/oss/uploadfe/png/136ab038823398bb7fd7b824becefed3.png);
            }
            .book-publish-wrap {
                @include props-dpr(margin-top, 70px);
                @include props-dpr(width height, 1264px 879px);
                @include props-dpr(padding, 140px 40px 0 80px);
                background-size: 100% 100%;
                background-image: url(https://oss.5rs.me/oss/uploadfe/png/2b00647623e3af755688300f101c36fd.png);
            }
        }

        .center-container {
            .visit-data-wrap {
                @include props-dpr(width height, 1422px 897px);
                @include props-dpr(padding, 120px 30px 20px 10px);
                background-size: 100% 100%;
                background-image: url(https://oss.5rs.me/oss/uploadfe/png/1cb895a4afdef21f0e448df0b31ef5e1.png);
            }
            .application-data-wrap {
                @include props-dpr(margin-top, 44px);
                @include props-dpr(width height, 1431px 837px);
                @include props-dpr(padding, 140px 130px 20px 100px);
                background-size: 100% 100%;
                background-image: url(https://oss.5rs.me/oss/uploadfe/png/386124bff94e5cfc455834d7ba1e7a9d.png);
            }
        }

        .right-container.resource-ranking-wrap {
            @include props-dpr(width height, 955px 1764px);
            @include props-dpr(padding, 114px 70px 20px 60px);
            background-size: 100% 100%;
            background-image: url(https://oss.5rs.me/oss/uploadfe/png/de85718340c5b1c8c82fbbdd81e0c91d.png);
            position: relative;
            &::before {
                content: "";
                display: block;
                @include props-dpr(width height, 837px 1486px);
                background-size: 100% 100%;
                background-image: url(https://oss.5rs.me/oss/uploadfe/png/b9f6cb9cc5b40102c024057449bcc9a7.png);
                position: absolute;
                @include props-dpr(top left, 230px 48px);
            }
            .table-ranking-list {
                thead {
                    @include props-dpr(height, 150px);
                }
                tbody tr td {
                    color: #00AFFF;
                    @include props-dpr(font-size, 34px);
                }
                .keyWord {
                    @include props-dpr(width, 220px);
                    @include ellipsis;
                }
                .circulation {
                }
                .th_hotValue .TitleItem_VsDDOj99 {
                    @include props-dpr(width, 170px);
                }
                .hotValue {
                    @include props-dpr(width, 200px);
                }
                .hot-icon {
                    display: inline-block;
                    @include props-dpr(margin, 0 6px);
                    @include props-dpr(width height, 20px 27px);
                    background-size: 100% 100%;
                    background-image: url(https://oss.5rs.me/oss/uploadfe/png/c1c00957d62a76afc5f41321d90bc001.png);
                    &.active {
                        background-image: url(https://oss.5rs.me/oss/uploadfe/png/6b57c442195d0bf5e44426c4332aedf9.png);
                    }
                }
            }
        }
    }


    .change-screen {
        z-index: 9999;
        position: fixed;
        @include props-dpr(right bottom, 100px 280px);
    }
}
