@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

#realTime {
  .real-body {
    position: relative;
    @include props-dpr(height width, 1722px 3570px);
    @include props-dpr(margin, 83px 138px 87px 143px);

    .box-left {
      @include props-dpr(width height margin-top, 1840px 1722px 30px);
      float: left;
      position: relative;
    }

    .box-right {
      float: left;
      @include props-dpr(width, 1730px);
    }
  }
  .mask{
    @include props-dpr(
                  height width font-size,
                  400px 800px 60px
  );
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin:auto;
    background: rgba(0,0,0,0.3);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    span:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      animation: ellipsis 1.5s infinite;
      content: "\2026";
      }
      @keyframes ellipsis {
      from {
      width: 2px;
      }
      to {
      width: 15px;
      }
      }
  }
  .change-screen {
    z-index: 9999;
    position: fixed;
    @include props-dpr(right bottom, 100px 280px);
  }
}
