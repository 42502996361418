
@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.Table_VsDDOj99 {
  position: relative;
  z-index: 2;
  & > table td {
    color: #fff;
    text-align: center;
    @include props-dpr(font-size height, 36px 68px);
    font-family: Source Han Sans CN;
    font-weight: 400;
  }
  .empty-tip {
    @include props-dpr(padding-top, 30px);
    text-align: center;
    color: #ddd;
  }
}