@charset "UTF-8";
@import "./mixin";
@import "./variables";
@import "./common";

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

i {
  font-style: normal;
}

p {
  margin: 0;
  padding: 0;
}

body {
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  background: url(https://file.5rs.me/oss/uploadfe/png/f5ffd88849e964acf7367db90f431f85.png) no-repeat 100% 100%;
  background-size: 100% 100%;
  background-color: #232433;
  overflow: hidden;
  #realtime {
    width: 100%;
  }
}

#root {
  display: flex;
  width: 100vw;
  background-size: 100% 100%;
  @include props-dpr(padding-bottom, 83px);
  overflow-x: hidden;
}
.clear:after {
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
