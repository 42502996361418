@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.CountItem_zqTzZz00 {
  @include props-dpr(max-width, 300px);
  .title-content {
    @include props-dpr(height line-height font-size, 36px 36px 36px);
    @include props-dpr(padding, 0 12px);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
  }
  .text-wrap {
    @include props-dpr(height, 80px);
    @include props-dpr(margin-top, 20px);
    position: relative;
    &::before,
    &::after {
      content: "";
      display: block;
      @include props-dpr(--prefix height, 20px 80px);
      width: calc(100% - var(--prefix));
      @include props-dpr(background-size, 315px 80px);
      background-image: url(https://oss.5rs.me/oss/uploadfe/png/cd2d9c7d53e230f928cb6c47b75ee33c.png);
      position: absolute;
      top: 0;
    }
    &::before {
      left: 0;
      background-position: left center;
    }
    &::after {
      right: 0;
      background-position: right center;
    }
    .text-content {
      width: 100%;
      height: 100%;
      @include props-dpr(padding, 0 10px);
      @include props-dpr(min-width, 150px);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #00B6FF;
      @include props-dpr(font-size, 55px);
      font-family: Avenir Next;
      font-weight: normal;
      position: relative;
      z-index: 2;
      &.hight-light {
        color: #FF8800;
      }
    }
  }
}