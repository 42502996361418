@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.VisitChart_sJZZ9733 {
  display: flex;
  justify-content: space-around;
  .visit-amount-wrap {
    @include props-dpr(width, 620px);
    &.visit-person .title-wrap .title-img {
      @include props-dpr(width height, 260px 86px);
      background-image: url(https://oss.5rs.me/oss/uploadfe/png/2b3a04772a6381493dae0dd0a3eb75e0.png);
    }
    .title-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .title-img {
        @include props-dpr(width height, 318px 85px);
        background-size: 100% 100%;
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/088464346b6eb0d958a924292ffd482a.png);
      }
      .icon-img {
        @include props-dpr(width height, 25px 50px);
        background-size: 100% 100%;
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/5ba495f297555f99130e2af636568550.png);
      }
    }
    .amount-wrap {
      display: flex;
      justify-content: center;
      .number-content {
        // flex-shrink: 0;
        @include props-dpr(width height, 68px 76px);
        @include props-dpr(margin, 12px 10px 0);
        background: linear-gradient(0deg, #A5FBFE 0%, #03B8F4 99%);
        @include props-dpr(font-size, 54px);
        font-family: Avenir Next;
        font-weight: normal;
        color: #005376;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .chart-title {
      @include props-dpr(margin, 60px 0 20px);
      @include props-dpr(font-size, 30px);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
    }
    .Chart4Visit {
      @include props-dpr(margin, 0 auto);
      @include props-dpr(width height, 620px 440px);
      // border: 2px solid red;
    }
  }
}