﻿@charset "UTF-8";

/*字体设置*/
@font-face {
  font-family: "GROTESKIA";
  src: url("../fonts/GROTESKIA.eot") format("embedded-opentype"),
    url("../fonts/GROTESKIA.woff") format("woff"),
    url("../fonts/GROTESKIA.ttf") format("truetype"),
    url("../fonts/GROTESKIA.svg#webfont34M5alKg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/*字体类*/
.font-groteskia {
  font-family: GROTESKIA, "Microsoft YaHei UI";
}

.clearfix {
  @extend %clearfix;
}

.left {
  float: left;
}

.right {
  float: right;
}
