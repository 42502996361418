@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.TitleItem_VsDDOj99 {
  display: inline-block;
  @include props-dpr(padding, 20px 34px);
  @include props-dpr(height, 73px);
  @include props-dpr(font-size line-height, 36px 36px);
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  position: relative;
  &::before,
  &::after {
    content: '';
    display: block;
    @include props-dpr(height, 73px); // 231px 73px
    @include props-dpr(--prefix, 20px);
    width: calc(100% - var(--prefix));
    @include props-dpr(background-size, 231px 73px);
    background-image: url(https://oss.5rs.me/oss/uploadfe/png/5b8784af50950c3d7f9bf69603046f62.png);
    position: absolute;
    top: 0;
  }
  &::before {
    left: 0;
    background-position: left center;
  }
  &::after {
    right: 0;
    background-position: right center;
  }
  .title-content {
    position: relative;
    z-index: 1;
    @include props-dpr(height line-height font-size, 36px 36px 36px);
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
  }
}
