@charset "UTF-8";
@import "../../assets/sass/_mixin.scss";

.Header {
  position: relative;
  @include props-dpr(height, 264px);
  width: 100vw;
  background-color: #20212b;
  box-shadow: 0px 8px 51px 0px rgba(13, 14, 26, 0.53);
  z-index: 9000;
  .box {
    @include props-dpr(height, 264px);
    .img-box {
      display: block;
      float: left;
      background: url('https://oss.5rs.me/oss/uploadfe/png/09017897bc38f655abbfc41e40fab964.png') no-repeat 100% 100%;
      background-size: 100% 100%;
      @include props-dpr(height width margin-top margin-left margin-right, 116px 316px 103px 114px 30px);
    }
    .system-version {
      display: block;
      float: left;
      @include props-dpr(font-size, 110px);
      @include props-dpr(margin, 80px 47px 0 38px);
      color: #78b3ff;
    }

    .page-title {
      display: block;
      float: left;
      @include props-dpr(font-size, 88px);
      @include props-dpr(margin, 101px 46px 0 0);
      color: #fefefe;
    }

    .page-desc {
      display: block;
      float: left;
      @include props-dpr(font-size, 50px);
      @include props-dpr(margin, 147px 0 0 0);
      color: #888d90;
    }
  }
  .Date {
    position: absolute;
    top: 0;
    right: 0;
  }
}
