@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.user-top {
  @include props-dpr(
                  height width padding-left padding-top margin-top,
                  1052px 1713px 20px 62px 40px
  );
  background: url(~@/assets/images/usertop.png) no-repeat center, url(~@/assets/images/wg.png) no-repeat center;
  background-size: 100% 100%;
  border: 1px solid transparent;

  .user-title {
    @include props-dpr(font-size letter-spacing margin-left, 54px 1px 65px);
    color: #fefefe;
  }

  .user-box {
    position: relative;
    @include props-dpr(height margin-top, 880px 40px);
    overflow: hidden;
    width: 100%;

    .user-real-time {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @include props-dpr(height, 880px);

      .user-li {
        position: relative;
        @include props-dpr(height, 118px);
        width: 100%;

        .li-box {
          width: 53%;
          @include props-dpr(height padding-left, 135px 40px);
          @include ellipsis-lines(1);
          display: flex;

          .user-time {
            display: inline-block;
            vertical-align: middle;
            @include props-dpr(font-size line-height, 38px 118px);
            color: #99abb4;
          }

          .user-img {
            display: inline-block;
            position: relative;
            vertical-align: middle;
            @include props-dpr(height width margin-left margin-right, 125px 125px 23px 32px);
            box-shadow: 0 0 16px 0 rgba(38, 50, 60, 0.22);

            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }

            .user-span {
              display: inline-block;
              @include props-dpr(height width, 40px 40px);
              border-radius: 50%;
              position: absolute;
              bottom: 0;
              right: 0;

              .ic {
                color: #fff;
                @include props-dpr(font-size, 22px);
                @extend %center-translate;
              }
            }

            .man {
              background-color: #1a25c9;
            }

            .woman {
              background-color: #d81764;
            }
          }

          .user-text {
            display: inline-block;
            vertical-align: middle;
            flex: 1;

            p {
              @include props-dpr(font-size line-height width, 34px 40px 600px);
              color: #fff;
              line-height: 1.3;
              @include ellipsis-lines(2);
            }

            span {
              display: block;
              @include props-dpr(font-size line-height width, 30px 38px 600px);
              color: #78b3ff;
              @include props-dpr(margin-top, 10px);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .user-out {
          @include props-dpr(width height padding-right, 680px 125px 40px);
          width: 40%;
          vertical-align: middle;
          text-align: right;

          p {
            @include props-dpr(font-size line-height width, 34px 40px 680px);
            color: #fff;
            float: right;
            line-height: 1.4;
          }

          span {
            display: inline-block;
            @include props-dpr(font-size line-height width, 30px 38px 680px);
            @include props-dpr(margin-top, 10px);
            color: #99abb4;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap
          }
        }
      }
    }
  }
}

.user-bottom {
  @include props-dpr(height width margin-top padding-left padding-top, 449px 1714px 60px 20px 61px);
  background: url(~@/assets/images/userbottom.png) no-repeat 100% 100%;
  background-size: 100% 100%;
  border: 1px solid transparent;

  .user-bottom-title {
    @include props-dpr(font-size letter-spacing margin-left, 54px 1px 81px);
    color: #fefefe;
  }

  .user-bottom-ul {
    display: flex;
    // justify-content: space-between;

    position: relative;
    @include props-dpr(height width margin-left, 351px 1535px 89px);

    .user-bottom-li {
      @include props-dpr(width, 320px);
      @include props-dpr(margin, 45px 20px 0 20px);

      .user-bottom-img {
        @include props-dpr(height width margin-left, 180px 180px 51px);

        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
        }
      }

      p {
        @include props-dpr(
                        font-size line-height margin-top margin-bottom,
                        38px 38px 14px 12px
        );
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }

      .user-city {
        @include props-dpr(font-size line-height, 38px 38px);
        color: #78b3ff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }
    }
  }
}