@charset "UTF-8";

// switch
//-----------------------------------------------------
$borderBoxSwitch: true !default; // true为*{box-sizing:border-box;}

// font相关
//-----------------------------------------------------
$fontSize: 14px !default;
$padFontSize: 12px !default;
$fontLineHeight: 1.5 !default;
$fontFamily: "Microsoft Yahei", Arial, sans-serif !default;

// 背景色，文本色，边框色，链接色
//-----------------------------------------------------
$colorText: #666 !default;
$colorLink: #666 #319dd8 !default; // 如果只有一个值则滑过的值为这个值的darken 5%，如果有两个值则第二个值为滑过的值
$colorTextLight: #999 !default;
$colorTextDark: #333 !default;
$colorBg: #fff !default;
$colorBgLight: #f8f8f8 !default;
$colorBorder: #ddd !default;
$colorBorderPrimary: #e1e8ee !default;
$colorPrimary: #319cd8 !default; //主色
$colorPrimaryLight: #46bdff !default; //辅色
$colorWarning: #ff8463 !default; //对比色
$colorDanger: #f00 !default;
$colorPurple: #c97196 !default;
$colorPlaceholder: #ccc !default; // input placeholder color
$colorDisabled: #999 #f8f8f8 #ccc !default; // textColor bgColor borderColor
$colorBgImg: #ecf3f8 !default; //图片未加载出来之前的占位背景色
$colorCorrect: #19bfbd !default;
$colorError: #ff6b6b !default;
$colorTableBtn: #3b77aa !default;

// Colors
// -----------------------------------------------------------------------------
$black: #000 !default;
$gray3: #333 !default;
$gray5: #555 !default;
$gray6: #666 !default;
$gray7: #777 !default;
$gray9: #999 !default;
$grayC: #ccc !default;
$grayD: #ddd !default;
$grayE: #eee !default;
$grayF: #f5f5f5 !default;
$white: #fff !default;

$orange: #ff8463 !default;
$yellow: #f8b020 !default;
$red: #f00 !default;
$green: #5cb85c !default;
$greenLight: #77cdb0 !default;
$sliver: #7da0b4 !default;
$purpleLight: #b3becf !default;

//skin color
$blue: #09c !default;
$blueLight: #00aee8 !default;
$blueDark: #0087b4 !default;

// radius
//-----------------------------------------------------
$radiusBase: 5px !default;
$radiusSmall: 2px !default;

// btn
// 默认的按钮大小
//-----------------------------------------------------
$btnSize: 16px 32px !default; // 默认按钮的左右padding，高度
$btnSizeSm: 14px 28px !default;

// input
// 默认的输入框大小
//-----------------------------------------------------
$inputSize: 12px 32px !default; // 默认输入框的左右padding，高度
$inputSizeSm: 10px 28px !default;

// z-index
//-----------------------------------------------------
$zIndexHeader: 10100 !default;
$zIndexSidebar: 10200 !default;
$zIndexFooter: 10300 !default;
$zIndexPopup: 10400 !default;
$zIndexOverlay: 10500 !default; // 默认高于header和footer部分

// margin
//-----------------------------------------------------
$marginLarge: 30px !default;
$marginBase: 20px !default;
$marginSmall: 14px !default;

$baseMargin: 20px !default;
$basePadding: 20px !default;
$padBasePadding: 20px !default;

// header,footer等的高度
//-----------------------------------------------------
$headerHeight: 40px !default;
$headerNavHeight: 40px !default;
$padHeaderHeight: 40px !default;
$footerHeight: 50px !default;
$sidebarWidth: 180px !default;

// Popup
//-----------------------------------------------------
$popupMask: rgba(0, 0, 0, 0.5) !default;
$popupBg: #fff !default;
$popupText: #333 !default;

//media
//-----------------------------------------------------
$tablet-width: 768px;
$desktop-width: 1024px;
$smDesktop-width: 1366px;
