@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.Maps {
  @include props-dpr(height width, 1567px 1840px);
  position: relative;

  .img-box {
    @include props-dpr(height width, 691px 1811px);

    .img-ul {
      @include props-dpr(height width, 691px 1750px);
      overflow: hidden;

      @keyframes imageRun {
        0% {
          opacity: 0.9;
          transform: scale(0.8);
        }
        50% {
          opacity: 0.1;
          transform: scale(1);
        }
        100% {
          opacity: 0.9;
          transform: scale(0.8);
        }
      }

      .img-li {
        float: left;
        @include props-dpr(height width margin-right margin-bottom, 253px 253px 26px 33px);

        &.style1 {
          animation: imageRun 3s linear infinite;
        }
        &.style3 {
          animation: imageRun 3s 0.2s linear infinite;
        }
        &.style2 {
          animation: imageRun 3s 0.8s linear infinite;
        }
        &.style4 {
          animation: imageRun 3s 0.4s linear infinite;
        }
        &.style5 {
          animation: imageRun 3s 1s linear infinite;
        }
        &.style6 {
          animation: imageRun 3s 0.6s linear infinite;
        }

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .map-box {
    background: url(~@/assets/images/maps.png) no-repeat 100% 100%;
    background-size: 100% 100%;
    @include props-dpr(height width left bottom, 1013px 1228px 178px 70px);
    position: relative;

    @keyframes Twinkle {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    .dot {
      position: absolute;
      animation: Twinkle 5s linear infinite;

      @include props-dpr(height width, 40px 40px);
      .container {
        position: relative;
        .circle {
          position: absolute;
          border-radius: 50%;
          transform: translate(-50%, -50%);
          &.first-map {
            z-index: 10;
            display: block;
            background-color: #e8eef4;
          }
          &.second-map {
            z-index: 5;
            display: block;
            background-color: #85868e;
          }
          &.three-map {
            display: block;
            background-color: #5f606b;
            z-index: 2;
          }
        }
      }
    }
  }

  .popup-box {
    @include props-dpr(
        height width border-radius padding-top padding-left padding-right,
        585px 394px 10px 18px 21px 21px
    );
    background-color: #2e313e;
    position: absolute;
    top: 50%;
    left: 40%;
    z-index: 1000;
    transform: translate(1000px, 180px) scale(0);
    animation: moveProp 3s cubic-bezier(0, 0, 0.45, 1);

    .popup-img {
      @include props-dpr(height width, 394px 394px);
      @include props-dpr(border-radius, 10px);
    }

    p {
      @include props-dpr(font-size line-height, 51px 51px);
      @include props-dpr(margin, 20px 0 25px 9px);
      color: #ffffff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .popup-information {
      .popup-city {
        float: left;
        span {
          @include props-dpr(font-size line-height, 49px 49px);
          color: #0087f2;
          text-align: left;
        }
      }

      .user-span {
        display: block;
        position: relative;
        @include props-dpr(height width, 53px 53px);
        border-radius: 50%;
        float: right;

        .ic {
          display: block;
          position: absolute;
          @include props-dpr(font-size line-height top left, 29px 29px 15px 13px);
          color: #ffffff;
        }

        &.man {
          background-color: #1a25c9;
        }

        &.woman {
          background-color: #d81764;
        }
      }
    }
  }

  @keyframes moveProp {
    0% {
      transform: translate(1000px, 180px) scale(0) rotateY(720deg);
    }
    25% {
      transform: translate(0, 0) scale(1) rotateY(0);
    }
    75% {
      transform: translate(0, 0) scale(1) rotateY(0);
    }
    100% {
      transform: translate(0, 0) scale(0) rotateY(0);
    }
  }
}