@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.BookRecommend {
  display: flex;
  flex-wrap: wrap;
  // justify-content: fle;
  width: 100%;
  height: 90%;
  overflow: hidden;
  .book-item-wrap {
    @include props-dpr(width height, 300px 340px);
    @include props-dpr(margin, 20px 13px 100px);
    position: relative;
    animation: slideUp 0.5s ease-in;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      @include props-dpr(width height, 300px 161px);
      background-size: 100% 100%;
      background-image: url(https://oss.5rs.me/oss/uploadfe/png/1848d3c7b8954dfaf1f122d5e77897ee.png);
    }
    .book-img {
      margin: 0 auto;
      position: relative;
      z-index: 1;
      @include props-dpr(width height, 200px 280px); // TODO: size
      // background-color: rgba($color: #fff, $alpha: 0.1);
      background-size: 100% 100%;
      border-radius: 6%;
    }
  }

  @keyframes slideUp {
    0% {
      @include props-dpr(margin-top, 60px);
      opacity: 0.5;
    }
    100% {
      @include props-dpr(margin-top, 20px);
      opacity: 1;
    }
  }
}