@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.Applications_sJZZ9733 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .app-item-wrap {
    @include props-dpr(margin-bottom, 110px);
    @include props-dpr(width, 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    .app-img {
      @include props-dpr(width height, 200px 140px);
      background-size: 100% 100%;
      &.img_1 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/3dac53d96b27d24ef4155f9a318b3f84.png);
      }
      &.img_2 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/ed1877b59b7a04d678034bb9a1514152.png);
      }
      &.img_3 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/263861aabe49faff15e197f4264d10d4.png);
      }
      &.img_4 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/6dfc706e6cc6e812cc629985e5917401.png);
      }
      &.img_5 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/bf66e2bb28f47b02a299a60e0b506661.png);
      }
      &.img_6 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/6391de41bb3a178af36447f775e39a4b.png);
      }
      &.img_7 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/f7c885f6680844a5ed465d8e91a84ad2.png);
      }
      &.img_8 {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/5f7ae0503a26ebfb85fd9c9aed8b5d12.png);
      }
    }
    .app-title {
      @include props-dpr(margin, 30px auto 16px);
      @include props-dpr(font-size, 36px);
      line-height: 1;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #00C0FF;
      text-align: center;
    }
    .app-visit-count {
      @include props-dpr(font-size, 25px);
      font-family: Avenir Next;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
    }
  }
}