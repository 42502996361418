@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

#Operation {
  .operation-item {
    @include props-dpr(height width border-radius line-height margin-top, 100px 100px 10px 100px 60px);
    background-color: #434758;
    opacity: 0.8;
    cursor: pointer;
    text-align: center;
    position: relative;
    &:hover {
      cursor: pointer;
      .jump-wrap {
        display: block;
      }
    }
    .jump-wrap {
      display: none;
      @include props-dpr(border-radius, 20px);
      background-color: #434758;
      color: #fff;
      font-weight: bold;
      @include props-dpr(padding, 20px 0px);
      @include props-dpr(font-size, 34px);
      position: absolute;
      @include props-dpr(right bottom, 100px 0);
      z-index: 9;
      .page-item {
        @include props-dpr(padding, 0 20px);
        white-space: nowrap;
        &:hover {
          background-color: darken($color: #434758, $amount: 6);
        }
      }
    }
  }
  .iconfont {
    color: white;
  }
  .iconshuaxin{
    font-size: 20px;
  }
  .iconshezhi{
    font-size: 18px;
  }
}
