@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.ProcessList {

  .process-title-wrap {
    @include props-dpr(width, 2760px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-item-wrap {
      @include props-dpr(width height, 488px 181px);
      background-size: 100% 100%;
      background-image: url(https://oss.5rs.me/oss/uploadfe/png/13d4353ea6d50a6e66f1ea63f20d8e71.png);
      @include props-dpr(font-size, 88px);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background-image 0.3s ease;
      &.active {
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/eefb18b9261191741248f951d173530b.png);
      }
    }
    .arrow-icon {
      content: "";
      display: block;
      @include props-dpr(width height, 138px 99px);
      background-size: 100% 100%;
      background-image: url(https://oss.5rs.me/oss/uploadfe/png/f037b16e56935db24da522c92622143f.png);
    }
  }

  .image-list-wrap {
    @include props-dpr(margin-top, 50px);
    @include props-dpr(padding, 70px 110px 0);
    @include props-dpr(width height, 2760px 660px);
    @include props-dpr(border, 6px solid #215AFF);
    @include props-dpr(border-radius, 20px);
    position: relative;
    .triangle-icon {
      @include props-dpr(--borderWidth, 30px);
      @include triangle(top, var(--borderWidth), #215AFF);
      position: absolute;
      @include props-dpr(top, -32px);
      transition: left 0.3s ease;
      &.icon_0 {
        @include props-dpr(left, 200px);
      }
      &.icon_1 {
        @include props-dpr(left, 980px);
      }
      &.icon_2 {
        @include props-dpr(left, 1760px);
      }
      &.icon_3 {
        @include props-dpr(left, 2520px);
      }
    }
    .image-list {
      display: flex;
      justify-content: space-between;
      .image-item-wrap {
        @include props-dpr(width height border-radius, 560px 510px 5px);
        position: relative;
        transition: all 0.3s ease-in;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-image: url(https://oss.5rs.me/oss/uploadfe/png/089006f32d13db9547f4cce7548f8bf9.png);
          @include props-dpr(background-size, 578px 526px);
          background-position: center center;
          background-repeat: no-repeat;
          z-index: 1;
        }
        .image-item {
          width: 100%;
          height: 100%;
          background-size: 100% 100%;
          transition: all 0.3s ease-in;
        }
      }
      .image-empty {
        @include props-dpr(width height border-radius, 437px 510px 5px);
        background-size: 100% 100%;
        background-image: url(https://oss.5rs.me/oss/uploadfe/png/c3691c8c20e9f852f2abfa2df16d9e18.png);
        transition: all 0.3s ease-in;
      }
    }
  }
}