@charset "UTF-8";
@import "@/assets/sass/_mixin.scss";

.SettingModal-box {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9999;
  background-color: rgba(0,0,0,0.3);
  #SettingModal {
    @include props-dpr(
      width height,
      1508px 800px
    );
    z-index: 9999;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &::before,
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 60%;
      background-size: 100%;
      background-image: url("https://oss.5rs.me/oss/uploadfe/png/ba837ad73f92fa7175bde96c527ba28c.png");
      position: absolute;
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      background-position: left bottom;
      bottom: 0;
      left: 0;
    }

    .setting-container {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
      @include props-dpr(padding-top, 200px);
      box-sizing: border-box;
    }

    .SettingModal-title{
      position: absolute;
      z-index: 1;
      @include props-dpr(left top font-size margin-left, 50% 12px 48px -40px);
      font-weight: bold;
      color: white;
    }
    
    .btn-box {
      position: absolute;
      @include props-dpr(bottom, 121px);
      display: flex;
      justify-content: space-around;
      width: 100%;
      color: white;
      .confirm-btn {
        @include props-dpr(
          width height border-radius line-height font-size,
          260px 100px 20px 100px 48px
        );
        text-align: center;
        background-image: linear-gradient(
          0deg,
          #3877ea 0%,
          #1e5bc1 50%,
          #124ead 73%,
          #3877ea 100%
        );
        box-shadow: 0px -2px 5px 0px rgba(0, 12, 34, 0.6),
          inset 0px 3px 3px 0px #7c9eeb;
        border-style: solid;
        border-width: 1px;
        border-image-source: linear-gradient(0deg, #5486e6 0%, #a9c8ff 100%);
        border-image-slice: 1;
        border: none;
        cursor: pointer;
      }
      .cancel-btn {
        @include props-dpr(
          width height border-radius line-height font-size,
          260px 100px 20px 100px 48px
        );
        text-align: center;
        box-shadow: 0px -2px 5px 0px rgba(0, 12, 34, 0.6),
          inset 0px 0px 6px 0px #7c9eeb;
        border-style: solid;
        border-width: 1px;
        border-image-source: linear-gradient(0deg, #5486e6 0%, #a9c8ff 100%);
        border-image-slice: 1;
        border: none;
        cursor: pointer;
      }
    }
    .play-style {
      color: white;
      .autoPlay,.handPlay{
        display: inline-block;
      }
      @include props-dpr(margin-left font-size, 120px 48px);
      .play-style-label {
        color: #78b3ff;
        @include props-dpr(margin-right, 60px);
      }
      input {
        @include props-dpr(height width margin-right, 36px 36px 19px);
      }
      .play-style-hand {
        @include props-dpr(margin-right , 100px );
      }
    }

    .data-sources {
      display: flex;
      @include props-dpr(margin-top margin-left font-size, 125px 120px 48px);
      .data-sources-label {
        display: inline-block;
        vertical-align: top;
        color: #78b3ff;
        @include props-dpr(margin-right width, 0px 600px);
      }
      .publish-list {
        display: flex;
        flex-wrap: wrap;
        .publish-item {
          color: white;
          display: inline-flex;
          align-items: center;
          @include props-dpr(margin-right width margin-bottom, 50px 500px 40px);
          input{
            flex-shrink: 0;
            @include props-dpr(height width margin-right, 36px 36px 19px);
          }
          &-label {
            overflow:hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow:ellipsis;
          }
        }
      }
    }
  }
}
